import { ReactNode } from 'react';
import { ButtonProps, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { LoadingButton } from '@mui/lab';

type OwnProps = {
  children: ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  withMargin?: boolean;
  form?: string;
} & ButtonProps;

export const SubmitButton = ({
  children,
  isDisabled,
  withMargin = true,
  form,
  ...props
}: OwnProps) => {
  const { is_submit_available, is_submit_pending } = useAppSelector((state) => state.app.app);

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      type="submit"
      form={form}
      disabled={isDisabled || !is_submit_available}
      loading={is_submit_pending}
      sx={{
        padding: '6px 32px',
        position: 'relative',
        minHeight: '40px',
        width: 'fit-content',
        ...(withMargin && {
          marginTop: '24px',
        }),
      }}
      {...props}
    >
      <Typography sx={{ lineHeight: 1 }}>{children}</Typography>
    </LoadingButton>
  );
};

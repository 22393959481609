import React, { ReactNode, useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppConnectionStatus } from 'types/application';
import applicationActions from 'redux/actions/application';

interface DispatchProps {
  updateConnectionStatus: (connectionStatus: AppConnectionStatus) => void;
}

interface OwnProps {
  children: ReactNode;
}

type Props = DispatchProps & OwnProps;

const ConnectionListenerWrapper = (props: Props) => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  useEffect(() => {
    props.updateConnectionStatus({
      isOffline: !isOnline,
    });
    // eslint-disable-next-line
  }, [isOnline]);

  return <>{props.children}</>;
};

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    updateConnectionStatus: bindActionCreators(applicationActions.updateConnectionStatus, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ConnectionListenerWrapper);

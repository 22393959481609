import paths from '../../paths';
import React, { lazy } from 'react';
import { fetchWidgetIntegrationsCall } from 'api/app/restaurant/widgetIntegration';
import { getPoliciesTermsCall } from 'api/app/restaurant/settings/policiesAndTerms';
import { Guard } from 'app/components/Security/Guard';
import { Access } from 'hooks/useGuard';
import GenericError from 'app/components/ErrorDisplay/GenericError';
import { getWidgetFieldsCall } from 'api/app/restaurant/widgetFields';

const PoliciesAndTermsPage = lazy(() => import('app/pages/RestaurantSettings/PoliciesAndTerms'));
const WidgetSettingsPage = lazy(() => import('app/pages/RestaurantSettings/WidgetSettings'));
const WidgetFields = lazy(() => import('app/pages/RestaurantSettings/WidgetFields'));
const WidgetIntegrationPage = lazy(() => import('app/pages/RestaurantSettings/WidgetIntegration'));

const onlineReservations = [
  {
    path: paths.settings.onlineReservations.widgetSettings,
    element: (
      <Guard
        access={Access.ManageWidgetSettings}
        component={<WidgetSettingsPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.onlineReservations.widgetFields,
    loader: async () => await getWidgetFieldsCall(),
    element: <WidgetFields />,
  },
  {
    path: paths.settings.onlineReservations.policiesAndTerms,
    loader: async () => await getPoliciesTermsCall(),
    element: <PoliciesAndTermsPage />,
  },
  {
    path: paths.settings.onlineReservations.widgetIntegration,
    loader: async () => await fetchWidgetIntegrationsCall(),
    element: <WidgetIntegrationPage />,
  },
];

export default onlineReservations;

import { ReactNode } from 'react';
import { Box } from '@mui/material';
import ScrollArea from 'app/components/ScrollArea';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  children: ReactNode;
  scrollRef?: any;
}

export const SettingsViewWrapper = ({ children, scrollRef }: OwnProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <ScrollArea style={{ borderRadius: theme.borderRadius.small }} scrollRef={scrollRef}>
        <Box sx={{ width: '100%' }}>{children}</Box>
      </ScrollArea>
    </Box>
  );
};

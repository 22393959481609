import { StyledCard } from 'app/pages/RestaurantSettings/_components/StyledCard';
import { ReactNode, useState } from 'react';
import { CardHeader } from 'app/pages/RestaurantSettings/_components/CustomStyledCard/_components/CardHeader';
import { Box } from '@mui/material';

interface OwnProps {
  children: ReactNode;
  additionalElements?: ReactNode;
  title: string | ReactNode;
  editMode?: boolean;
}

export const CustomStyledCard = ({ children, title, additionalElements }: OwnProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExtendElement = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <StyledCard
      sx={{
        borderTop: '8px solid',
        borderTopColor: 'darkBlue',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }}>
        <CardHeader
          toggleExtendElement={toggleExtendElement}
          isExpanded={isExpanded}
          title={title}
        />
        {additionalElements}
      </Box>
      {isExpanded && children}
    </StyledCard>
  );
};

import adminEmailSettings from 'app/router/routes/admin/adminEmailSettings';
import paths from 'app/router/paths';
import { lazy } from 'react';
import adminLogs from 'app/router/routes/admin/adminLogs';

const AdminDashboardPage = lazy(() => import('app/pages/Admin/Dashboard'));

const admin = [
  {
    path: paths.admin.dashboard,
    element: <AdminDashboardPage />,
  },
  ...adminEmailSettings,
  ...adminLogs,
];

export default admin;

export enum MessageVariants {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum NotificationTypes {
  Enqueued = 'Notifications.SnackbarEnqueued',
  Closed = 'Notifications.SnackbarClosed',
  Removed = 'Notifications.SnackbarRemoved',
}

export enum NotificationChannel {
  EMAIL = 'MAIL',
  SMS = 'SMS',
}

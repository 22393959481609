import { Box, SxProps } from '@mui/material';
import { useStatus } from 'hooks/useStatus';
import { useAppSelector } from 'redux/hooks';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';

interface OwnProps {
  startName: string;
  startLabel: string;
  endName: string;
  endLabel: string;
  sx?: SxProps;
}

export const StartEndTimePickers = ({ startName, startLabel, endName, endLabel, sx }: OwnProps) => {
  const { isReadOnly } = useStatus();
  const { interval } = useAppSelector((state) => state.app.settings.reservation);

  const generateFullDayTimeOptions = (interval: number) => {
    const options = [];
    const dayInSeconds = 24 * 60 * 60;
    for (let i = 0; i < dayInSeconds; i = i + interval) {
      const totalMinutes = i / 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const label = `${formattedHours}:${formattedMinutes}`;

      options.push({
        value: i,
        label: label,
      });
    }
    return options;
  };

  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <MuiSelect
        disabled={isReadOnly}
        name={startName}
        options={generateFullDayTimeOptions(interval)}
        label={startLabel}
        sx={sx}
        translateLabels={false}
        shouldValidate={true}
      />
      <MuiSelect
        disabled={isReadOnly}
        name={endName}
        options={generateFullDayTimeOptions(interval)}
        label={endLabel}
        sx={sx}
        translateLabels={false}
        shouldValidate={true}
      />
    </Box>
  );
};

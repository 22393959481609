import { useGuard, Access } from 'hooks/useGuard';
import { ReactNode } from 'react';

type Props = {
  access: Access;
  component?: ReactNode;
  children?: ReactNode;
  fallbackComponent?: ReactNode;
};

export const Guard = ({ access, component, children, fallbackComponent = null }: Props) => {
  const { hasAccess } = useGuard();
  return hasAccess(access) ? <>{component || children}</> : <>{fallbackComponent}</>;
};

import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';

interface OwnProps {
  inputStyles: object;
  minName: string;
  maxName: string;
  control: Control;
  errors: any;
  isEdit: boolean;
  field?: any;
}

export const MinMaxGuestsRange = ({
  inputStyles,
  control,
  errors,
  minName,
  maxName,
  isEdit,
  field,
}: OwnProps) => {
  const { trigger, watch, clearErrors } = useFormContext();
  const minGuests = watch(minName);
  const maxGuests = watch(maxName);

  useEffect(() => {
    if (Number(minGuests) >= Number(maxGuests)) {
      (async () => {
        await trigger([minName, maxName]);
      })();
    } else {
      clearErrors([minName, maxName]);
    }
  }, [minGuests, maxGuests]);

  return (
    <Box>
      {isEdit ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: { xs: '100%', sm: 'unset' },
          }}
        >
          <Controller
            control={control}
            name={minName}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="number"
                inputProps={{ min: '1', max: '998', step: '1' }}
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          -
          <Controller
            control={control}
            name={maxName}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="number"
                inputProps={{ min: '1', max: '999', step: '1' }}
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
        </Box>
      ) : (
        <Typography>
          {field.min_guests} - {field.max_guests}
        </Typography>
      )}
      <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
        <CustomErrorMessage errors={errors} name={minName} />
        <CustomErrorMessage errors={errors} name={maxName} />
      </Box>
    </Box>
  );
};

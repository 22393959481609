export const allData: any = {
    allReservations: {
    values: [
        { id: 0 },
        { startTime: '' },
        { title: '' },
        { endTime: '' },
        { status: '' },
        { covers: 0 },
        { origin: '' },
        { tags: '' },
        ]
    },
    allTables: {
        values: []
    },
    allClients: {
        values: []
    },
    allCurrencies: {
    values: [
        { currency: '' },
        { totalValue: '' }
        ]
    },
    allSpecialOffers: {
        values: []
    },
    allComments: {
        values: []
    },
    allFloorPlan: {
        values: []
    },
    allFloorPlanRooms: {
        values: []
    },
    allFloorPlanTables: {
        values: []
    },
    allFloorPlanWalls: {
        values: []
    },
    allFloorPlanTexts: {
        values: []
    },
    restaurant: {
        values: []
    },
    restaurantSettings: {
        values: []
    },
    fullTables: {
        values: [],
    },
    rooms: {
        values: [],
    },
    roomsAndTables: {
        values: []
    }
};
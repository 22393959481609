import { createBrowserRouter, Outlet } from 'react-router-dom';
import { PrivateRoute } from 'app/components/PrivateRoute';
import Authenticated from 'app/components/Authenticated';
import auth from 'app/router/routes/auth';
import calendar from 'app/router/routes/calendar';
import settings from 'app/router/routes/settings/settings';
import mozrest from 'app/router/routes/mozrest';
import HubspotChatProvider from 'app/components/HubspotChatProvider';
import admin from 'app/router/routes/admin/admin';
import AdminPageWrapper from 'app/pages/Admin/_components/AdminPageWrapper';
import GenericError from 'app/components/ErrorDisplay/GenericError';
import RestaurantSettingsPageWrapper from 'app/pages/RestaurantSettings/_components/RestaurantSettingsPageWrapper';

const router = createBrowserRouter([
  ...auth,
  ...mozrest,
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Authenticated />
        <HubspotChatProvider />
      </PrivateRoute>
    ),
    errorElement: <GenericError />,
    children: [
      ...calendar,
      {
        element: <RestaurantSettingsPageWrapper />,
        children: [
          {
            element: <Outlet />,
            errorElement: <GenericError />,
            children: [...settings],
          },
        ],
      },
      {
        element: <AdminPageWrapper />,
        children: [
          {
            element: <Outlet />,
            errorElement: <GenericError />,
            children: [...admin],
          },
        ],
      },
    ],
  },
]);

export default router;

import { Box, Typography } from '@mui/material';
import { INumberOption } from 'types/app/config';
import { convertSecondsToHours } from 'utils/date-time/convertSecondsToHours';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

interface OwnProps {
  fieldName: string;
  options: INumberOption[];
  isEdit: boolean;
  currentFieldValue?: number;
}

export const BookingLengthRange = ({ fieldName, options, isEdit, currentFieldValue }: OwnProps) => {
  const { trigger, watch, clearErrors } = useFormContext();
  const length = watch(fieldName);

  useEffect(() => {
    if (Number(length)) {
      (async () => {
        await trigger([fieldName]);
      })();
    } else {
      clearErrors([fieldName]);
    }
  }, [length]);

  return (
    <Box>
      {isEdit ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
          <MuiSelect name={fieldName} options={options} sx={{ width: '140px', flex: 'unset' }} />
        </Box>
      ) : (
        <>
          {!!currentFieldValue && (
            <Typography>{convertSecondsToHours(currentFieldValue)}</Typography>
          )}
        </>
      )}
    </Box>
  );
};

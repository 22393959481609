import { Box, IconButton, IconButtonProps } from '@mui/material';
import { ReactComponent as ChevronDownIcon } from 'images/icons/ic-chevron-down-v3.svg';
import { MainHeading } from 'app/components/TextElements/MainHeading';
import { ReactNode } from 'react';

interface OwnProps {
  toggleExtendElement: () => void;
  isExpanded: boolean;
  title: string | ReactNode;
}

export const CardHeader = ({ toggleExtendElement, isExpanded, title }: OwnProps) => {
  const ExtendedIcon = (props: IconButtonProps) => (
    <IconButton
      {...props}
      sx={{
        padding: 0,
        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
        color: 'navyBlue',
      }}
    >
      <ChevronDownIcon />
    </IconButton>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '12px',
          flexWrap: 'wrap',
          alignItems: { xs: 'flex-start', sm: 'center' },
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', flex: 1 }}>
          {typeof title === 'string' ? (
            <Box
              onClick={toggleExtendElement}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <MainHeading>{title}</MainHeading>
              <ExtendedIcon />
            </Box>
          ) : (
            <>
              {title}
              <ExtendedIcon onClick={toggleExtendElement} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

import SettingsWrapper from 'app/components/Layout/SettingsWrapper';
import adminSettingsSubNavigationLinks from 'app/pages/Admin/_components/AdminPageWrapper/_config/adminSettingsSubNavigationLinks';
import { useTranslation } from 'react-i18next';

const AdminPageWrapper = () => {
  const { t } = useTranslation();

  return <SettingsWrapper menuItems={adminSettingsSubNavigationLinks(t)} />;
};

export default AdminPageWrapper;

import { ProgressOverlay } from 'app/components/Overlays/ProgressOverlay';
import { useTheme } from '@mui/material/styles';

interface OwnProps {
  isLoader?: boolean;
  isLoading?: boolean;
}

export const LoadingOverlay = ({ isLoader = true, isLoading = false }: OwnProps) => {
  const theme = useTheme();

  //TODO is loading for specific views
  return (
    <ProgressOverlay
      open={isLoading}
      isLoader={isLoader}
      sx={{ borderRadius: theme.borderRadius.default }}
    />
  );
};

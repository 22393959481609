import { createContext, useContext } from 'react';

type AuthContextProps = {
  token: string;
  setTokenToLocalStorage: (data: any) => void;
  handleLogout: () => void;
};

export const AuthContext = createContext<AuthContextProps>({
  token: '',
  setTokenToLocalStorage: () => {},
  handleLogout: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
}

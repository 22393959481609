export const prepareRestaurantFromLocalDatabase = (data: any) => {
  const { restaurant, restaurantSettings } = data;
  const restaurantValues = restaurant.values[0];
  const {
    date_format,
    first_day_of_week,
    language,
    restaurant_id,
    time_zone,
    opening_hours,
    exclusive_days,
    increment,
    list_show_cancelled,
    list_show_walk_ins,
    show_big_spender_tag,
  } = restaurantSettings.values[0];

  const parsedOpeningHours = JSON.parse(opening_hours);
  const parsedExclusiveDays = JSON.parse(exclusive_days);

  const preparedRestaurantData = {
    restaurant: {
      nid: restaurantValues.id,
      ...restaurantValues,
      settings: {
        increment,
        date_format_key: date_format,
        first_day_of_week,
        language,
        restaurant_id,
        timezone: time_zone,
        open_hours: parsedOpeningHours,
        exclusive_days: parsedExclusiveDays,
        list_show_cancelled: list_show_cancelled !== '0',
        list_show_walk_ins: list_show_walk_ins !== '0',
        show_big_spender_tag: show_big_spender_tag !== '0',
      },
    },
  };

  return preparedRestaurantData;
};

import { CustomBookingLength } from 'types/app/timeManagement';
import { useTranslation } from 'react-i18next';
import { SideModal } from 'app/components/Modals/SideModal';
import { Button, Typography } from '@mui/material';

type Props = {
  overlapped: CustomBookingLength[];
  onClose: () => void;
};

const BookingLengthOverlappingModal = ({ overlapped, onClose }: Props) => {
  const { t } = useTranslation();
  const overlappedNames = overlapped.map((item) => item.name).join(', ');

  return (
    <SideModal
      title={t('bookingLengthAreOverlappingTitle')}
      withSaveButton={false}
      open={overlapped.length > 0}
      onClose={onClose}
      additionalElements={
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
      }
    >
      <Typography>{`${t('bookingLengthAreOverlappingMessage')} ${overlappedNames}.`}</Typography>
    </SideModal>
  );
};

export default BookingLengthOverlappingModal;

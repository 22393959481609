import { StyledTableRow } from 'app/pages/RestaurantSettings/_components/StyledTableRow';
import { Button, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  oneHourInSeconds,
  timeManagementFieldNames,
  twoHoursInSeconds,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { BookingLengthRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/BookingLengthRange';
import { MinMaxGuestsRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/MinMaxGuestsRange';
import { doesRangesOverlap } from 'app/pages/RestaurantSettings/TimeManagement/_utils';
import { INumberOption } from 'types/app/config';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';

interface OwnProps {
  options: INumberOption[];
  inputStyles: object;
  openOverlappingModal: () => void;
}

export const AddRange = ({ options, inputStyles, openOverlappingModal }: OwnProps) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    trigger,
    formState: { errors, isLoading },
  } = useFormContext();

  const ranges = getValues(timeManagementFieldNames.ranges);

  const addRangeToFields = async () => {
    const newRange = {
      min_guests: +getValues(timeManagementFieldNames.min_guests),
      max_guests: +getValues(timeManagementFieldNames.max_guests),
      min_length: +getValues(timeManagementFieldNames.min_length),
      max_length: +getValues(timeManagementFieldNames.max_length),
    };

    if (newRange.min_guests > newRange.max_guests) {
      setError(timeManagementFieldNames.min_guests, {
        type: 'custom',
        message: t('minGuestsGreaterThanMaxMessage'),
      });
    }

    if (newRange.min_length > newRange.max_length) {
      setError(timeManagementFieldNames.min_length, {
        type: 'custom',
        message: t('minLengthGreaterThanMaxMessage'),
      });
    }

    if (Object.keys(errors).length && !isLoading) return;

    const isValid = await trigger([
      timeManagementFieldNames.min_guests,
      timeManagementFieldNames.max_guests,
      timeManagementFieldNames.min_length,
      timeManagementFieldNames.max_length,
    ]);

    if (!isValid) return;

    if (doesRangesOverlap(ranges, newRange)) {
      openOverlappingModal();
      return;
    }

    clearErrors(timeManagementFieldNames.ranges);
    setValue(timeManagementFieldNames.ranges, [...ranges, newRange]);
    setValue(timeManagementFieldNames.min_guests, 1);
    setValue(timeManagementFieldNames.max_guests, 2);
    setValue(timeManagementFieldNames.min_length, oneHourInSeconds);
    setValue(timeManagementFieldNames.max_length, twoHoursInSeconds);
  };

  return (
    <StyledTableRow>
      <TableCell>
        <MinMaxGuestsRange
          isEdit={true}
          minName={timeManagementFieldNames.min_guests}
          maxName={timeManagementFieldNames.max_guests}
          control={control}
          errors={errors}
          inputStyles={inputStyles}
        />
      </TableCell>
      <TableCell>
        <BookingLengthRange
          isEdit={true}
          fieldName={timeManagementFieldNames.max_length}
          options={options}
        />
      </TableCell>
      <TableCell>
        <BookingLengthRange
          isEdit={true}
          fieldName={timeManagementFieldNames.min_length}
          options={options}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ minWidth: '105px' }}
          onClick={addRangeToFields}
        >
          {t('addRange')}
        </Button>
        <CustomErrorMessage errors={errors} name={timeManagementFieldNames.ranges} />
      </TableCell>
    </StyledTableRow>
  );
};

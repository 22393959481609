import { Table } from 'types/calendar';

export const prepareRoomsAndTablesFromLocalDatabase = (data: any) => {
  const {
    roomsAndTables: { values },
  } = data;

  const groups: any = [];
  const rooms: any = [];

  values.forEach((roomAndTable: any) => {
    const room = roomAndTable;
    const parsedTables = JSON.parse(room.tables);
    groups.push({
      id: room.id,
      roomId: Number(room.id),
      title: room.title,
      root: true,
      collapsed: room.collapsed !== '0',
    });
    parsedTables.forEach((table: Table) => {
      groups.push({
        id: `${table.nid}`,
        tableId: table.nid,
        title: table.title,
        root: false,
        parent: Number(room.id),
        covers_min: table.covers_min,
        covers_max: table.covers_max,
      });
    });
    rooms.push({
      changed: room.changed,
      collapsed: room.collapsed !== '0',
      created: room.created,
      nid: Number(room.id),
      language: room.language,
      status: room.status !== '0',
      tables: parsedTables,
      title: room.title,
    });
  });
  return {
    groups,
    rooms,
  };
};

import SettingsWrapper from 'app/components/Layout/SettingsWrapper';
import { settingsSubNavigationLinks } from 'app/pages/RestaurantSettings/_components/RestaurantSettingsPageWrapper/_config/settingsSubNavigationLinks';
import { useTranslation } from 'react-i18next';

const RestaurantSettingsPageWrapper = () => {
  const { t } = useTranslation();

  return <SettingsWrapper menuItems={settingsSubNavigationLinks(t)} />;
};

export default RestaurantSettingsPageWrapper;

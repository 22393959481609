import { Permission, UserRole } from 'types/app/users';
import { useSelector } from 'react-redux';
import { AppState } from 'types';

export enum Access {
  IsOwner = 'IS_OWNER',
  CreateRestaurant = 'CREATE_RESTAURANT',
  ViewCalendar = 'VIEW_CALENDAR',
  ViewList = 'VIEW_LIST',
  ViewFloorPlan = 'VIEW_FLOOR_PLAN',
  ManageFloorPlan = 'MANAGE_FLOOR_PLAN',
  ManageRestaurantInfo = 'MANAGE_RESTAURANT_INFO',
  ManageTimeAndLanguage = 'MANAGE_TIME_AND_LANGUAGE',
  ManageReservationDuration = 'MANAGE_RESERVATION_DURATION',
  ManageOwnerAccount = 'MANAGE_OWNER_ACCOUNT',
  ManageUsers = 'MANAGE_USERS',
  ManageDiningRoomsAndAreas = 'MANAGE_DINING_ROOMS_AND_AREAS',
  ManageTableGroups = 'MANAGE_TABLE_GROUPS',
  ManageOpeningHours = 'MANAGE_OPENING_HOURS',
  ManageCustomPeriods = 'MANAGE_CUSTOM_PERIODS',
  ManageCalendarSettings = 'MANAGE_CALENDAR_SETTINGS',
  ManageEmailSettings = 'MANAGE_EMAIL_SETTINGS',
  ManageSmsSettings = 'MANAGE_SMS_SETTINGS',
  ManageFeedbackSettings = 'MANAGE_FEEDBACK_SETTINGS',
  ManageWidgetSettings = 'MANAGE_WIDGET_SETTINGS',
  ManageWidgetFields = 'MANAGE_WIDGET_FIELDS',
  ManageWidgetIntegration = 'MANAGE_WIDGET_INTEGRATION',
  ManagePoliciesAndTerms = 'MANAGE_POLICIES_AND_TERMS',
  ManageMarketingChannels = 'MANAGE_MARKETING_CHANNELS',
  ManageExperiences = 'MANAGE_EXPERIENCES',
  ManageEvents = 'MANAGE_EVENTS',
  ManageSpecialOffers = 'MANAGE_SPECIAL_OFFERS',
  ManagePrepaymentsAndDeposits = 'MANAGE_PREPAYMENTS_AND_DEPOSITS',
  Manage3rdPartyIntegrations = 'MANAGE_3RD_PARTY_INTEGRATIONS',
  ManageBanners = 'MANAGE_BANNERS',
  ManageRestaurantProfile = 'MANAGE_RESTAURANT_PROFILE',
  ManageGallery = 'MANAGE_GALLERY',
  ManageMenuSettings = 'MANAGE_MENU_SETTINGS',
  ManageMenuEditor = 'MANAGE_MENU_EDITOR',
  ManageSubscriptionAndBilling = 'MANAGE_SUBSCRIPTION_AND_BILLING',
  ViewInvoices = 'VIEW_INVOICES',
  QuickSettings = 'QUICK_SETTINGS',
  DatabaseRead = 'QUICK_SETTINGS',
  DatabaseDownload = 'QUICK_SETTINGS',
  AdminSettings = 'ADMIN_SETTINGS',
}

type AccessPermissionMap = {
  [key in Access]?: {
    roles: UserRole[];
    condition?: 'OR' | 'AND';
    permissions?: Permission;
  };
};

const accessPermissionMap: AccessPermissionMap = {
  [Access.IsOwner]: {
    roles: [UserRole.Owner],
  },
  [Access.DatabaseRead]: {
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.DatabaseDownload]: {
    roles: [UserRole.Owner],
  },
  [Access.DatabaseRead]: {
    permissions: Permission.Database,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.CreateRestaurant]: {
    roles: [UserRole.Owner],
  },
  [Access.ViewCalendar]: {
    roles: [
      UserRole.Owner,
      UserRole.Manager,
      UserRole.Staff,
      UserRole.ReadOnly,
      UserRole.Marketing,
    ],
  },
  [Access.ViewList]: {
    roles: [
      UserRole.Owner,
      UserRole.Manager,
      UserRole.Staff,
      UserRole.ReadOnly,
      UserRole.Marketing,
    ],
  },
  [Access.ViewFloorPlan]: {
    roles: [
      UserRole.Owner,
      UserRole.Manager,
      UserRole.Staff,
      UserRole.ReadOnly,
      UserRole.Marketing,
    ],
  },
  [Access.ManageRestaurantInfo]: {
    permissions: Permission.RestaurantInformation,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageTimeAndLanguage]: {
    permissions: Permission.TimeLanguage,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageReservationDuration]: {
    permissions: Permission.TableTimeManagement,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageOwnerAccount]: {
    roles: [UserRole.Owner],
  },
  [Access.ManageUsers]: {
    permissions: Permission.Users,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageDiningRoomsAndAreas]: {
    permissions: Permission.DiningRoomsAndAreas,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageTableGroups]: {
    permissions: Permission.TableGroup,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageFloorPlan]: {
    permissions: Permission.FloorPlanSetup,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageOpeningHours]: {
    permissions: Permission.OpeningHours,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageCustomPeriods]: {
    permissions: Permission.CustomPeriods,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageCalendarSettings]: {
    permissions: Permission.CalendarReservationsSettings,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.ManageEmailSettings]: {
    permissions: Permission.EmailSettings,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageSmsSettings]: {
    permissions: Permission.SmsSettings,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageWidgetSettings]: {
    permissions: Permission.WidgetSettings,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageWidgetFields]: {
    permissions: Permission.WidgetFields,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManagePoliciesAndTerms]: {
    permissions: Permission.PolicesAndTerms,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageWidgetIntegration]: {
    permissions: Permission.WidgetIntegration,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageMarketingChannels]: {
    roles: [],
  },
  [Access.ManageExperiences]: {
    roles: [],
  },
  [Access.ManageEvents]: {
    roles: [],
  },
  [Access.ManageSpecialOffers]: {
    roles: [],
  },
  [Access.ManagePrepaymentsAndDeposits]: {
    permissions: Permission.PaymentsAndDeposits,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.Manage3rdPartyIntegrations]: {
    permissions: Permission.Integrations,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.ManageBanners]: {
    roles: [],
  },
  [Access.ManageRestaurantProfile]: {
    roles: [],
  },
  [Access.ManageGallery]: {
    roles: [],
  },
  [Access.ManageMenuSettings]: {
    roles: [],
  },
  [Access.ManageMenuEditor]: {
    roles: [],
  },
  [Access.ManageSubscriptionAndBilling]: {
    permissions: Permission.SubscriptionAndBilling,
    roles: [UserRole.Owner],
  },
  [Access.ViewInvoices]: {
    permissions: Permission.Invoices,
    roles: [UserRole.Owner, UserRole.Manager],
  },
  [Access.QuickSettings]: {
    permissions: Permission.QuickSidebarSettings,
    roles: [UserRole.Owner, UserRole.Manager, UserRole.Marketing],
  },
  [Access.AdminSettings]: {
    roles: [UserRole.Admin, UserRole.WebAdmin, UserRole.WebDeveloper],
  },
};

export const useGuard = () => {
  const { role, permissions } = useSelector((state: AppState) => state.app.profile);

  const hasAccess = (access: Access) => {
    const tableinUser = [UserRole.Admin, UserRole.WebAdmin, UserRole.WebDeveloper].includes(role);

    if (tableinUser && !access.startsWith('ADMIN')) {
      return true;
    }

    const permissionMap = accessPermissionMap[access];
    const condition = permissionMap?.condition || 'OR';
    const checkUserRoles = permissionMap?.roles.includes(role) || false;
    const checkPermissions = permissionMap?.permissions
      ? permissions.includes(permissionMap.permissions)
      : false;

    return condition === 'OR'
      ? checkUserRoles || checkPermissions
      : checkUserRoles && checkPermissions;
  };

  return {
    hasAccess: (access: Access) => hasAccess(access),
    isReadOnly: role === UserRole.ReadOnly,
  };
};

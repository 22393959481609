import moment from 'moment';
import { TagSelector } from 'app/components/FormElements/TagSelector';

interface OwnProps {
  name: string;
  label: string;
}

export const WeekdaySelector = ({ name, label }: OwnProps) => {
  const weekdays = Array(7)
    .fill('')
    .map((_, i) => {
      const date = moment().day((i + 1) % 7);
      return { label: date.format('dddd'), value: date.day() };
    });

  return <TagSelector name={name} label={label} tags={weekdays} />;
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import store from './redux/store';
import lastUsed from 'locize-lastused';

import 'moment/locale/sq'; // Albanian
import 'moment/locale/ar'; // Arabic
import 'moment/locale/az'; // Azerbaijani
import 'moment/locale/bg'; // Belgian
import 'moment/locale/ca'; // Catalan
import 'moment/locale/zh-cn'; // Simplified Chinese
import 'moment/locale/zh-tw'; // Traditional Chinese
import 'moment/locale/hr'; // Croatian
import 'moment/locale/cs'; // Czech
import 'moment/locale/da'; // Danish
import 'moment/locale/nl'; // Dutch
import 'moment/locale/en-gb'; // British English
import 'moment/locale/et'; // Estonian
import 'moment/locale/fi'; // Finnish
import 'moment/locale/fr'; // French
import 'moment/locale/de'; // German
import 'moment/locale/el'; // Greek
import 'moment/locale/hu'; // Hungarian
import 'moment/locale/is'; // Icelandic
import 'moment/locale/it'; // Italian
import 'moment/locale/ja'; // Japanese
import 'moment/locale/lv'; // Latvian
import 'moment/locale/lt'; // Lithuanian
import 'moment/locale/nn'; // Norwegian
import 'moment/locale/pl'; // Polish
import 'moment/locale/pt'; // Portuguese
import 'moment/locale/ro'; // Romanian
import 'moment/locale/ru'; // Russian
import 'moment/locale/sl'; // Slovenian
import 'moment/locale/es'; // Spanish
import 'moment/locale/sv'; // Swedish
import 'moment/locale/th'; // Thai
import 'moment/locale/vi'; // Vietnamese
import moment from 'moment-timezone';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const isProduction = process.env.NODE_ENV === 'production';

const adjustLocale = (lang: string) => {
  const localeMap: any = {
    sq: 'sq', // Albanian
    ar: 'ar', // Arabic
    az: 'az', // Azerbaijani
    bg: 'bg', // Bulgarian
    ca: 'ca', // Catalan
    'zh-hans': 'zh-cn', // Simplified Chinese
    'zh-hant': 'zh-tw', // Traditional Chinese
    hr: 'hr', // Croatian
    cs: 'cs', // Czech
    da: 'da', // Danish
    nl: 'nl', // Dutch
    en: 'en-gb', // British English
    et: 'et', // Estonian
    fi: 'fi', // Finnish
    fr: 'fr', // French
    de: 'de', // German
    el: 'el', // Greek
    hu: 'hu', // Hungarian
    is: 'is', // Icelandic
    it: 'it', // Italian
    ja: 'ja', // Japanese
    lv: 'lv', // Latvian
    lt: 'lt', // Lithuanian
    nn: 'nn', // Norwegian
    pl: 'pl', // Polish
    'pt-pt': 'pt', // Portuguese
    ro: 'ro', // Romanian
    ru: 'ru', // Russian
    sl: 'sl', // Slovenian
    es: 'es', // Spanish
    sv: 'sv', // Swedish
    th: 'th', // Thai
    vi: 'vi', // Vietnamese
  };

  return localeMap[lang] || 'en-gb';
};

const fetchLang = () => {
  let prevLang: null | string = null;

  store.subscribe(async () => {
    const lang = store.getState().app.profile.language || 'en';
    const adjustedLocale = adjustLocale(lang);
    moment.locale(adjustedLocale);

    if (lang && lang !== prevLang) {
      prevLang = lang;
      await i18n.changeLanguage(lang);
    }
  });
};

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID ?? '',
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY ?? '',
  referenceLng: process.env.REACT_APP_LOCIZE_REF_LANG ?? '',
};

if (!isProduction) {
  i18n.use(lastUsed);
}

i18n.use(Locize).use(initReactI18next).init({
  debug: false,
  returnNull: false,
  fallbackLng: 'en',
  lng: 'en',
  backend: locizeOptions,
  locizeLastUsed: locizeOptions,
  saveMissing: !isProduction,
});

fetchLang();

export default i18n;

import { SideModal } from 'app/components/Modals/SideModal';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GuestsOverlappingModal = ({ isOpen, onClose }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SideModal
      title={t('guestsAreOverlappingTitle')}
      withSaveButton={false}
      open={isOpen}
      onClose={onClose}
      additionalElements={
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
      }
    >
      <Typography>{t('guestAreOverlappingMessage')}</Typography>
    </SideModal>
  );
};

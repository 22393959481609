import moment from 'moment-timezone';
import {
  ClientDB,
  CommentDB,
  CurrencyDB,
  FloorPlanDB,
  FloorPlanTableDB,
  FloorPlanTextDB,
  FloorPlanWallDB,
  ReservationDB,
  SpecialOfferDB,
  TableDB,
} from './databaseTypes';

export const prepareReservationsFromLocalDatabase = (data: any) => {
  const getTable = (reservationId: number) =>
    data?.allTables?.values.find((i: any) => {
      return i.reservationId === reservationId;
    });

  const getClient = (reservationId: number) =>
    data?.allClients?.values.find((i: any) => {
      return i.reservationId === reservationId;
    });

  const getCurrency = (reservationId: number) =>
    data?.allCurrencies?.values.find((i: any) => {
      return i.reservationId === reservationId;
    });

  const getSpecialOffer = (reservationId: number) =>
    data?.allSpecialOffers?.values.find((i: any) => {
      return i.reservationId === reservationId;
    });

  const getComments = (reservationId: number) =>
    data?.allComments?.values.filter((i: any) => {
      return i.reservationId === reservationId;
    });

  const tables: FloorPlanTableDB[] = data?.allFloorPlanTables.values.map(
    (table: FloorPlanTableDB) => ({
      covers_min: table.covers_min,
      covers_max: table.covers_max,
      position: {
        entity_id: table.entity_id,
        bundle: table.bundle,
        restaurant_id: table.restaurant_id,
        x: table.x,
        y: table.y,
        angle: table.angle,
        width: table.width,
        height: table.height,
        shape: table.shape,
      },
      nid: table.nid,
      title: table.title,
      language: table.language,
      status: table.status,
      created: table.created,
      changed: table.changed,
      room_id: table.room_id,
    }),
  );

  const walls: FloorPlanWallDB[] = data?.allFloorPlanWalls.values.map((wall: FloorPlanWallDB) => ({
    id: wall.id,
    type: wall.type,
    restaurant_id: wall.restaurant_id,
    label: wall.label,
    position: {
      entity_id: wall.entity_id,
      bundle: wall.bundle,
      restaurant_id: wall.restaurant_id,
      x: wall.x,
      y: wall.y,
      angle: wall.angle,
      width: wall.width,
      height: wall.height,
      shape: wall.shape,
    },
  }));

  const texts: FloorPlanTextDB[] = data?.allFloorPlanTexts.values.map((text: FloorPlanTextDB) => ({
    id: text.id,
    type: text.type,
    restaurant_id: text.restaurant_id,
    label: text.label,
    position: {
      entity_id: text.entity_id,
      bundle: text.bundle,
      restaurant_id: text.restaurant_id,
      x: text.x,
      y: text.y,
      angle: text.angle,
      width: text.width,
      height: text.height,
      shape: text.shape,
    },
  }));

  const floorPlanData: FloorPlanDB = {
    restaurantId: data?.allFloorPlan?.values[0].restaurantId,
    rooms: data?.allFloorPlanRooms?.values,
    tables,
    walls,
    texts,
  };

  const reservationsData = data?.allReservations?.values?.map((item: ReservationDB) => {
    const tables: TableDB = getTable(item.id);
    const client: ClientDB = getClient(item.id);
    const currency: CurrencyDB = getCurrency(item.id);
    const specialOffer: SpecialOfferDB = getSpecialOffer(item.id);
    const comments: CommentDB[] = getComments(item.id);

    const formattedTables = tables?.tableIds.split(',');
    const formattedSpecialOffer =
      specialOffer?.specialOfferIds.length > 0
        ? specialOffer?.specialOfferIds.split(',').map((i) => ({ nid: i }))[0]
        : { nid: '_none' };
    const formattedTags = item.tags.length > 0 ? item.tags.split(',') : [];
    const formattedDinningRooms =
      item.dinningRooms.length > 0 ? item.dinningRooms.split(',').map((i) => parseInt(i)) : [];

    const clientIsVip = client?.vip !== '0';
    const clientIsBigSpender = client?.big_spender !== '0';
    const company = client?.company;
    const clientDetails = client?.clientDetails;
    const phoneNumber = client?.phone;
    const currencies =
      currency?.currency && currency?.totalValue
        ? { [`${currency?.currency}`]: currency?.totalValue }
        : [];

    const feedback = item?.feedbackId
      ? {
          rateAmbience: item?.rateAmbience,
          rateCleanliness: item?.rateCleanliness,
          rateFood: item?.rateFood,
          rateService: item?.rateService,
          rateComment: item?.rateComment,
          nid: item?.feedbackId,
          title: item?.feedbackTitle,
        }
      : null;

    return {
      id: item.id,
      group: formattedTables,
      title: item.title,
      start: moment(item.startTime).valueOf(),
      end: moment(item.endTime)?.valueOf() || moment(item.startTime).valueOf(),
      reservation: {
        id: item.id,
        name: client?.name,
        email: client?.email,
        status: item?.status,
        startTime: moment(item?.startTime),
        endTime: moment(item?.endTime),
        numberOfGuests: item?.covers,
        origin: item?.origin,
        tables: formattedTables,
        timeFormat: 'HH:mm',
        specialOffer: formattedSpecialOffer,
        clientIsVip,
        clientIsBigSpender,
        company,
        clientDetails,
        showBigSpenderTag: true,
        payments: {
          currencies,
          payments: [],
        },
        autoArrivalDisabled: false,
        comments,
        tags: formattedTags,
        dinningRooms: formattedDinningRooms,
        guestDetails: {
          arrivedCount: item?.arrivedCount,
        },
        phoneNumber,
        reservationAverageRating: {
          feedback,
        },
        averageRating: {
          feedbackCount: 0,
          ratings: null,
        },
      },
    };
  });
  return {
    reservationsData,
    floorPlanData,
  };
};

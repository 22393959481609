import accountSetup from 'app/router/routes/settings/accountSetup';
import onlineReservations from 'app/router/routes/settings/onlineReservations';
import calendarAndList from 'app/router/routes/settings/calendarAndList';
import marketingTools from 'app/router/routes/settings/marketingTools';
import websiteBuilder from 'app/router/routes/settings/websiteBuilder';
import tableSettings from 'app/router/routes/settings/tableSettings';
import notifications from 'app/router/routes/settings/notifications';

const settings = [
  ...accountSetup,
  ...tableSettings,
  ...calendarAndList,
  ...notifications,
  ...onlineReservations,
  ...marketingTools,
  ...websiteBuilder,
];

export default settings;

import * as yup from 'yup';
import { TFunction } from 'i18next';
import { CustomBookingLength } from 'types/app/timeManagement';
import store from 'redux/store';

export const serviceFieldNames = {
  name: 'name',
  valid_days: 'valid_days',
  start_time: 'start_time',
  end_time: 'end_time',
};

export const timeManagementFieldNames: any = {
  ...serviceFieldNames,
  ranges: 'ranges',
  min_guests: 'min_guests',
  max_guests: 'max_guests',
  min_length: 'min_length',
  max_length: 'max_length',
};

export const timeManagementHeadRows = [
  { name: 'timeManagementGuests' },
  { name: 'timeManagementDefaultBookingLength' },
  { name: 'timeManagementMinPossibleBookingLength' },
  { name: '' },
];

export const oneHourInSeconds = 3600;
export const twoHoursInSeconds = 7200;

const customBookingLengthDefaultValues = {
  [timeManagementFieldNames.name]: '',
  [timeManagementFieldNames.valid_days]: [],
  [timeManagementFieldNames.start_time]: 0,
  [timeManagementFieldNames.end_time]: 0,
};

const defaultBookingLengthDefaultValues = {
  [timeManagementFieldNames.ranges]: [],
  [timeManagementFieldNames.min_guests]: 1,
  [timeManagementFieldNames.max_guests]: 2,
  [timeManagementFieldNames.min_length]: oneHourInSeconds,
  [timeManagementFieldNames.max_length]: twoHoursInSeconds,
};

export const tableDefaultValues: any = {
  ...customBookingLengthDefaultValues,
  ...defaultBookingLengthDefaultValues,
};

export const createTimeManagementRangesValidationSchema = (t: TFunction) => {
  const minMaxValidation = {
    min_guests: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, t('timeManagementMinGuests'))
      .max(998, t('timeManagementMaxGuests'))
      .required(t('timeManagementMinGuestsIsRequired')),
    max_guests: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1, t('timeManagementMinGuests'))
      .max(999, t('timeManagementMaxGuests'))
      .required(t('timeManagementMaxGuestsIsRequired')),
    min_length: yup.number().required(t('timeManagementMinLengthIsRequired')),
    max_length: yup.number().required(t('timeManagementMaxLengthIsRequired')),
  };

  return {
    ranges: yup
      .array()
      .of(yup.object().shape(minMaxValidation))
      .min(1, t('atLeastOneRangeRequired')),
    ...minMaxValidation,
  };
};

export const overlappedBookingLengthRanges = (data: CustomBookingLength) => {
  return (
    store
      .getState()
      // If user is updating current booking length, filter out old booking length service. We only need to
      // compare new values from form with other booking lengths.
      .app.settings.time_management.custom_booking_length.filter(
        (bookingLength) => bookingLength.id !== data.id,
      )
      // Get booking services that has same valid days.
      .filter((bookingLength) =>
        bookingLength.valid_days?.some((weekDay) => data.valid_days?.includes(weekDay)),
      )
      .filter((bookingLength) => {
        // Skip this step if we don't have enough data.
        if (
          data.start_time === undefined ||
          data.end_time === undefined ||
          bookingLength.start_time === undefined ||
          bookingLength.end_time === undefined
        ) {
          return false;
        }

        // New booking length ends before existing one, no overlap.
        if (data.end_time <= bookingLength.start_time) {
          return false;
        }

        // New booking length starts after existing one, no overlap.
        if (data.start_time >= bookingLength.end_time) {
          return false;
        }

        return true;
      })
  );
};

import { Box, IconButton } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'images/icons/ic-trash.svg';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  isEdit: boolean;
  onDelete: () => void;
  onToggleEdit: () => void;
  onUpdate: () => void;
  allowToRemove: boolean;
}

export const ReservationLengthEdit = ({
  isEdit,
  onDelete,
  onToggleEdit,
  onUpdate,
  allowToRemove,
}: OwnProps) => {
  const { t } = useTranslation();

  if (isEdit) {
    return (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Button variant="outlined" color="error" onClick={onToggleEdit}>
          {t('cancel')}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onUpdate}>
          {t('update')}
        </Button>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex' }}>
      {allowToRemove && (
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
      <Button variant={'outlined'} onClick={onToggleEdit} color={'secondary'} size={'small'}>
        {t('edit')}
      </Button>
    </Box>
  );
};

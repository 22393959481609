import { lazy } from 'react';
import paths from 'app/router/paths';
import { getAdminDefaultEmailTemplatesCall } from 'api/app/admin/email/templates';

const EmailTemplatesSettingsPage = lazy(() => import('app/pages/Admin/Email/EmailTemplates'));

const adminEmailSettings = [
  {
    path: paths.admin.email.emailTemplates,
    element: <EmailTemplatesSettingsPage />,
    loader: async () => getAdminDefaultEmailTemplatesCall(),
  },
];

export default adminEmailSettings;

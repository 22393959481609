export const prepareNotesFromLocalDatabase = (data: any) => {
  const { notes } = data;
  return notes.values.map((note: any) => ({
    color: note.color,
    days: JSON.parse(note.days),
    days_excluded: JSON.parse(note.days_excluded),
    end_date: note.end_date,
    id: note.id,
    name: note.name,
    restaurant_id: note.restaurant_id,
    start_date: note.start_date,
    text: note.text,
    user_id: note.user_id,
  }));
}
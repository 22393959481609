import { CustomBookingLength } from 'types/app/timeManagement';
import { timeManagementFieldNames } from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { MainHeading } from 'app/components/TextElements/MainHeading';
import { Box } from '@mui/material';

type Props = {
  isCustom: boolean;
  isInEditMode: boolean;
  bookingLength: CustomBookingLength;
  onClick: () => void;
};

const FormCardHeaderTitle = ({ isCustom, isInEditMode, bookingLength, onClick }: Props) => {
  if (!isCustom) {
    return <>{bookingLength.name}</>;
  }

  if (isInEditMode) {
    return <ControlledInput name={timeManagementFieldNames.name} />;
  }

  return (
    <Box onClick={onClick}>
      <MainHeading
        sx={{
          textDecoration: 'underline',
          textDecorationStyle: 'dotted',
          textDecorationColor: '#c4c4c4',
          textDecorationThickness: '1px',
          textDecorationSkipInk: 'auto',
        }}
      >
        {bookingLength.name}
      </MainHeading>
    </Box>
  );
};

export default FormCardHeaderTitle;

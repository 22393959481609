import { SideModal } from 'app/components/Modals/SideModal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createTimeManagementRangesValidationSchema,
  overlappedBookingLengthRanges,
  tableDefaultValues,
  timeManagementFieldNames,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { Box } from '@mui/material';
import { useStatus } from 'hooks/useStatus';
import * as yup from 'yup';
import { WeekdaySelector } from 'app/components/WeekdaySelector';
import { StartEndTimePickers } from 'app/components/StartEndTimePickers';
import { MessageVariants } from 'enums/notifications';
import { useAppDispatch } from 'redux/hooks';
import { useSnackbar } from 'notistack';
import timeManagementActions from 'redux/actions/app/timeManagement';
import { ReservationLengthTable } from 'app/pages/RestaurantSettings/TimeManagement/_components/ReservationLengthTable';
import { CustomBookingLength } from 'types/app/timeManagement';
import { useEffect, useState } from 'react';
import BookingLengthOverlappingModal from 'app/pages/RestaurantSettings/TimeManagement/_components/BookingLengthOverlappingModal';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
}
export const ServiceModal = ({ isOpen, onClose }: OwnProps) => {
  const [overlappedBookingLengths, setOverlappedBookingLengths] = useState<CustomBookingLength[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isReadOnly } = useStatus();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const timeManagementRangeSchema = createTimeManagementRangesValidationSchema(t);

  const schema = yup.object().shape({
    name: yup.string().required(t('serviceNameIsRequired')),
    valid_days: yup.array().of(yup.number()).min(1, t('atLeastOneDayRequired')),
    ...timeManagementRangeSchema,
  });

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: tableDefaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, watch } = methods;

  const startTime: any = watch(timeManagementFieldNames.start_time);
  const endTime: any = watch(timeManagementFieldNames.end_time);

  const isValidStartTime = startTime <= endTime;

  const onReset = () => {
    reset(tableDefaultValues);
  };

  const onModalClose = () => {
    onClose();
    onReset();
  };

  const handleCreateCustomBookingLength = (data: CustomBookingLength) => {
    setIsLoading(true);
    const { name, valid_days, start_time, end_time, ranges } = data;

    (async () => {
      let snackbar = {
        message: t('createCustomBookingLengthSuccess'),
        variant: MessageVariants.Success,
      };

      try {
        await dispatch(
          timeManagementActions.createCustomBookingLength({
            name,
            valid_days,
            start_time,
            end_time,
            ranges,
          }),
        );
      } catch (error: any) {
        snackbar = {
          message: t('createCustomBookingLengthError'),
          variant: MessageVariants.Error,
        };
      } finally {
        enqueueSnackbar(snackbar.message, { variant: snackbar.variant });
        onModalClose();
        setIsLoading(false);
      }
    })();
  };

  const onSubmit = (data: CustomBookingLength) => {
    const overlapped = overlappedBookingLengthRanges(data);

    if (overlapped.length) {
      setOverlappedBookingLengths(overlapped);
      return;
    }

    handleCreateCustomBookingLength(data);
  };

  useEffect(() => {
    reset();
  }, [onClose]);

  return (
    <>
      <SideModal
        title={t('addServiceTitle')}
        open={isOpen}
        isLoading={isLoading}
        onClose={onModalClose}
        methods={methods}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        saveDisabled={!isValidStartTime || isReadOnly}
        paperSx={{ maxWidth: '760px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <ControlledInput label={t('serviceTitle')} name={timeManagementFieldNames.name} />
          <WeekdaySelector name={timeManagementFieldNames.valid_days} label={t('serviceDays')} />
          <StartEndTimePickers
            startName={timeManagementFieldNames.start_time}
            startLabel={t('serviceStartTime')}
            endName={timeManagementFieldNames.end_time}
            endLabel={t('serviceEndTime')}
          />
          <ReservationLengthTable />
        </Box>
      </SideModal>

      <BookingLengthOverlappingModal
        overlapped={overlappedBookingLengths}
        onClose={() => setOverlappedBookingLengths([])}
      />
    </>
  );
};

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTableCell } from 'app/pages/RestaurantSettings/_components/StyledTableCell';
import { CustomTable } from 'app/pages/RestaurantSettings/_components/CustomTable';
import {
  timeManagementFieldNames,
  timeManagementHeadRows,
} from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { useAppSelector } from 'redux/hooks';
import { TableRowRanges } from 'app/pages/RestaurantSettings/TimeManagement/_components/TableRowRanges';

export const ReservationLengthTable = () => {
  const { t } = useTranslation();
  const { reservation_durations } = useAppSelector((state) => state.app.config);

  const headRowsToRender = timeManagementHeadRows.map(({ name }, index) => (
    <StyledTableCell key={index}>{t(name)}</StyledTableCell>
  ));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <CustomTable
        headRowsToRender={headRowsToRender}
        rowsToRender={
          <TableRowRanges name={timeManagementFieldNames.ranges} options={reservation_durations} />
        }
      />
    </Box>
  );
};

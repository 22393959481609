import React, { lazy } from 'react';
import paths from '../../paths';
import { Guard } from 'app/components/Security/Guard';
import { Access } from 'hooks/useGuard';
import GenericError from 'app/components/ErrorDisplay/GenericError';

const ReservationSettingsPage = lazy(
  () => import('app/pages/RestaurantSettings/ReservationSettings'),
);

const calendarAndList = [
  {
    path: paths.settings.calendarAndList.settings,
    element: (
      <Guard
        access={Access.ManageCalendarSettings}
        component={<ReservationSettingsPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
];

export default calendarAndList;

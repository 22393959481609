import React, { ReactNode } from 'react';
import { Navigate, useNavigation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import paths from 'app/router/paths';
import Spinner from 'app/components/PrivateRoute/_styles/Spinner';

interface OwnProps {
  children: ReactNode;
}
export const PrivateRoute = ({ children }: OwnProps) => {
  const { token } = useAuth();
  const navigation = useNavigation();

  if (token) {
    return (
      <>
        {navigation.state === 'loading' && <Spinner />}
        {children}
      </>
    );
  }

  return <Navigate to={paths.auth.login} />;
};
